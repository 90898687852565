<template>
  <div id="app">
    <section class="hero">
      <div class="inner">
        <div class="hero__background">
          <img
            class="logo"
            src="@/assets/logo.png"
            alt="Logo Kraamzorg van Tamara"
          />
          <h1>
            Een oase<br />
            van rust<br />
            voor <span class="highlight">moeder</span><br />
            en <span class="highlight">kind</span>
          </h1>
        </div>
      </div>
    </section>

    <section class="introduction">
      <div class="inner">
        <p>Hallo!</p>

        <p>
          Wat leuk dat je mijn website bezoekt. Als je hier terecht
          bent gekomen, ben je waarschijnlijk op zoek naar een goede
          kraamverzorgster. Kijk gerust even rond om jezelf te
          oriënteren op wie ik ben en wat je kan verwachten als je mij
          inhuurt.
        </p>

        <img
          class="photo-collage-desktop"
          src="@/assets/photo-collage-desktop.jpg"
          alt="Foto collage"
        />

        <img
          class="photo-collage-mobile"
          src="@/assets/photo-collage-mobile.jpg"
          alt="Foto collage"
        />

        <p>
          <b>Eerst even een korte introductie:</b><br />
          Ik ben Tamara Dockx-Prins, geboren en getogen boven de
          rivieren en woon sinds 2010 samen met mijn partner en
          dochter in de gemeente Rucphen. Al 17 jaar ben ik
          kraamverzorgster waarvan 16 jaar als zelfstandige zonder
          personeel. Na een jaar vast dienstverband wist ik dat deze
          manier van kraamzorg niet is wat ik wilde en waar ik dus ook
          niet achter stond. Mijn passie is om met het oog op het
          gezin de zorg afstemmen op de behoefte en wensen van hun en
          de pasgeboren baby zodat ze na de kraamweek als “nieuw”
          gezin verder kunnen. Dit is wat ik met heel veel liefde en
          plezier doe.
        </p>

        <p>
          Het is belangrijk dat de kraamweek voor jullie een fijne,
          gezellige tijd is met persoonlijke aandacht zodat jullie je
          zoveel mogelijk op je gemak voelen.
        </p>

        <div>
          Een aantal voordelen van mij als kraamverzorgster:<br />

          <ul>
            <li>We ontmoeten elkaar al eerder in de zwangerschap.</li>
            <li>Je weet wie er komt.</li>
            <li>Je kan je wensen kenbaar maken.</li>
            <li>Ik rook niet.</li>
            <li>
              Ik heb veel ervaring met het geven van borstvoeding en
              kolven.
            </li>
            <li>Ik kan tips geven over het dragen van een baby.</li>
            <li>Ervaring met voedselallergie Coeliakie.</li>
            <li>Ik ben docent babymassage.</li>
            <li>EHBO homeopathie onderlegd.</li>
            <li>
              Bekend met homeopathie voor zwangerschap, bevalling en
              kraamweek.
            </li>
            <li>
              Ik vind het erg leuk om te koken en lekkere lunches te
              maken.
            </li>
            <li>
              Ik heb ervaring met
              <a
                href="https://www.facebook.com/KraamzorgVanTamara/posts/3731422093654032"
                >tweelingen</a
              >.
            </li>
          </ul>
        </div>

        <p>
          Als gediplomeerd kraamverzorgster sta ik ingeschreven bij de
          landelijke organisatie Kenniscentrum kraamzorg.<br />
          Om daar ingeschreven te mogen staan moet je elke vier jaar
          de vastgestelde aantal opleidingspunten behalen om zo aan te
          tonen dat je voldoet aan de gestelde eisen.
        </p>

        <p>
          Wilt u nog meer informatie, schroom dan niet om contact op
          te nemen, zodat ik u nog het een en ander kan uitleggen.
        </p>

        <p>Hopelijk tot snel,</p>

        <p>Liefs Tamara</p>
      </div>
    </section>

    <section>
      <div class="inner align-center">
        <h2>Wat anderen zeggen</h2>

        <div class="reviews">
          <template v-for="(review, index) in reviews">
            <div class="review" :key="`review-${index}`">
              <p v-html="review.message"></p>
              <p>
                <b>{{ review.author }}</b>
              </p>
            </div>
          </template>
        </div>
      </div>
    </section>

    <section class="contact">
      <div
        class="contact__background contact__background--calendar"
      ></div>
      <div class="inner">
        <p>
          Stuur mij een berichtje en dan ga ik kijken of er nog plek
          is rond jullie uitgerekende datum. Zo reserveer ik alvast
          een plekje, en ben je gegarandeerd van jullie keuze.
        </p>

        <p>
          Kraamzorg van Tamara is geen one size fits all. Ieder gezin
          heeft namelijk andere wensen of verwachtingen. Al tijdens
          onze eerste kennismaking kijken we of we bij elkaar passen
          om zo een fijne onbezorgde kraamweek te krijgen/hebben.
        </p>

        <p>
          Zodra je bent ingeschreven stel ik samen met jullie een
          contract op en kom ik tussen de 28e en 34e week van je
          zwangerschap langs voor een persoonlijke intake bij jullie
          thuis.
        </p>

        <div class="button-wrapper">
          <button class="button" @click="scrollToContactForm">
            Neem contact op
          </button>
        </div>
      </div>
    </section>

    <section>
      <div class="inner">
        <h2>Wat moet u doen</h2>
        <p>
          Kraamzorg van Tamara regelt alles, jullie hoeven alleen nog
          jullie zorgverzekeraar in te lichten. Ook kraamzorg van mij
          als zelfstandige kraamverzorgster wordt 100% vergoed
          afhankelijk van hoe u bent verzekerd. U heeft alleen nog de
          eventuele wettelijke eigen bijdrage van €4,60 per uur.
          (vastgesteld voor 2021)
        </p>
      </div>
    </section>

    <section>
      <div class="inner">
        <h2>Werkwijze</h2>
        <ul>
          <li>
            Dagelijks worden de medische controles van moeder en kind
            uitgevoerd en genoteerd.
          </li>
          <li>
            Tips en ondersteuning voor het geven van de borst of
            flesvoeding.
          </li>
          <li>Tips van het aan- en uitkleden van de baby.</li>
          <li>
            Uitleg: hoe doe je een baby in bad of tummy tub of
            douchen.
          </li>
          <li>
            Ondertussen wordt er licht huishoudelijk werk verricht en
            wordt er een wasje aangezet.
          </li>
          <li>
            Naast alle gezelligheid is er ook tijd voor een praatje en
            adviezen passend bij jullie.
          </li>
          <li>
            Naast de zorg voor moeder en kind worden andere
            gezinsleden ook niet vergeten.
          </li>
          <li>
            Ik zorg voor vrolijke voedende ontbijtjes en lunches en
            eventueel ook het avondeten en natuurlijk de
            tussendoortjes. Het is belangrijk om na zo’n grote
            inspanning gezond te eten en voldoende vitamines binnen te
            krijgen om zo weer goed te kunnen herstellen. Ik help
            jullie daar graag bij.
          </li>
          <li>
            Ik kan mij namelijk goed voorstellen dat jullie hoofd er
            nu niet naar staat om te koken of dat jullie tijd te kort
            komen door alle drukte. Dan maak ik graag een pan verse
            soep, nasi of een ovenschotel met lasagne klaar.
          </li>
          <li>
            Zijn er ook broertjes en zusjes? Dan maken we samen
            koekjes of cakejes om zo een leuke herinnering achter te
            laten.
          </li>
          <li>Niets moet, alles kan is mijn motto.</li>
        </ul>
      </div>
    </section>

    <section>
      <div class="inner">
        <h2>Social media</h2>

        <div class="fb-posts">
          <div class="fb-posts__col">
            <div class="fb-p">
              <a :href="fbPosts[0].link">
                <div
                  class="fb-p__text"
                  v-html="fbPosts[0].text"
                ></div>
                <div class="fb-p__photos">
                  <div
                    class="fb-p__photos__photo"
                    v-for="(image, imageIndex) in fbPosts[0].images"
                    :key="`fb-image--${imageIndex}`"
                    :style="`background-image: url(${image})`"
                  ></div>
                </div>
              </a>
            </div>
            <div class="fb-p">
              <a :href="fbPosts[2].link">
                <div
                  class="fb-p__text"
                  v-html="fbPosts[2].text"
                ></div>
                <div class="fb-p__photos">
                  <div
                    class="fb-p__photos__photo"
                    v-for="(image, imageIndex) in fbPosts[2].images"
                    :key="`fb-image--${imageIndex}`"
                    :style="`background-image: url(${image})`"
                  ></div>
                </div>
              </a>
            </div>
          </div>

          <div class="fb-posts__col">
            <div class="fb-p">
              <a :href="fbPosts[1].link">
                <div
                  class="fb-p__text"
                  v-html="fbPosts[1].text"
                ></div>
                <div class="fb-p__photos">
                  <div
                    class="fb-p__photos__photo"
                    v-for="(image, imageIndex) in fbPosts[1].images"
                    :key="`fb-image--${imageIndex}`"
                    :style="`background-image: url(${image})`"
                  ></div>
                </div>
              </a>
            </div>
            <div class="fb-p">
              <a :href="fbPosts[3].link">
                <div
                  class="fb-p__text"
                  v-html="fbPosts[3].text"
                ></div>
                <div class="fb-p__photos">
                  <div
                    class="fb-p__photos__photo"
                    v-for="(image, imageIndex) in fbPosts[3].images"
                    :key="`fb-image--${imageIndex}`"
                    :style="`background-image: url(${image})`"
                  ></div>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div class="align-center">
          Bekijk al mijn berichten op
          <a
            href="https://www.facebook.com/KraamzorgVanTamara"
            class="social-link"
          >
            <img
              src="@/assets/facebook.svg"
              width="16"
              alt=""
              class="social-icon"
            /><!-- -->Facebook</a
          >
          en
          <a
            href="https://www.instagram.com/kraamzorgvantamara/"
            class="social-link"
          >
            <img
              src="@/assets/instagram.svg"
              width="16"
              alt=""
              class="social-icon"
            /><!-- -->Instagram</a
          >.
        </div>
      </div>
    </section>

    <section>
      <div class="inner extra-info-wrapper">
        <div class="extra-info">
          <h2>Moeders voor Moeders</h2>
          <p>
            Wil je andere toekomstige moeders helpen? Dan zou je
            kunnen overwegen om mee te doen aan Moeders voor
            Moeders.<br />
            Zij zamelen urine in van pril zwangere vrouwen, waaruit
            het hCG-hormoon wordt gewonnen. Dit hormoon wordt door
            farmaceutische bedrijven gebruikt voor het vervaardigen
            van geneesmiddelen die worden gebruikt bij
            vruchtbaarheidsbehandelingen.
          </p>

          <p>
            Aanmelden kun je al meteen doen zodra je weet dat je
            zwanger bent. Je kunt dit doen vanaf dat je 6 weken
            zwanger bent tot en met de 16 de week.
          </p>

          <p>
            Zelfs al vul je maar één kan omdat je toch te misselijk
            bent of omdat het niet handig is op je werk: alle kleine
            beetje helpen enorm.
          </p>

          <p>
            <a href="https://www.moedersvoormoeders.nl/"
              >Bezoek Moeders voor Moeders</a
            >
          </p>
        </div>

        <div class="extra-info">
          <h2>Medipoint</h2>
          <p>
            Bij Medipoint kunt u de kraam hulpmiddelen regelen. Denk
            hierbij aan: bedklossen, rugsteun, douchekruk, ondersteek
            en meer.<br />
            Deze hulpmiddelen kunt u gratis lenen en worden ook bij u
            thuis bezorgd en weer opgehaald.
          </p>

          <p>
            <a
              href="https://www.medipoint.nl/informatie/zwanger/kraamhulpmiddelen?gclid=CjwKCAjwkJj6BRA-EiwA0ZVPVmhH8fIUq3pYJXhXquNKP6Hpqu295YIMiWJxue4A3DS1clT2gP2-sxoCDTUQAvD_BwE"
              >Bezoek Medipoint</a
            >
          </p>
        </div>
      </div>
    </section>

    <section>
      <div class="inner">
        <h2>Kosten</h2>

        <p>
          Afgezien van de wettelijke eigen bijdrage van €4,60 per uur
          (vastgesteld voor 2021), worden alle kosten volledig vergoed
          door uw zorgverzekeraar.<br />
          Afhankelijk van hoe u verzekerd bent, kan het zijn dat de
          eigen bijdrage in uw aanvullende pakket vergoed wordt.
        </p>

        <p>
          <b>Kraamzorg van mij als zelfstandige</b><br />
          Ik breng de aanvraag onder bij een organisatie voor
          zelfstandige kraamverzorgsters en zij regelen de facturatie.
          Heeft u hier nog vragen over, neem dan gerust contact met
          mij op. Dan zal ik u hierin begeleiden.
        </p>

        <p>
          Voorbeelden van organisaties zijn: Kraamzorg1op1 en
          Kraamzorggezocht.
        </p>
      </div>
    </section>

    <section>
      <div class="inner">
        <h2>Werkgebied</h2>

        <p>
          Mijn werkgebied omvat ongeveer alles wat tussen de 30/40 min
          rijden afstand ligt vanaf Sint Willebrord. Soms zelfs net
          over de grens richting Zuid-Holland.
        </p>

        <img src="@/assets/map.png" alt="Werkgebied" class="map" />

        <p>
          Gemeenten die hier sowieso onder vallen zijn: Bergen op
          Zoom, Breda, Dorst, Etten-Leur, Geertruidenberg,
          Halderbergen, Klundert, Roosendaal, Rucphen, Sint
          Willebrord, Sprundel, Terheijden, Zegge, Zevenbergen en
          Zundert.
        </p>

        <p>
          Staat uw plaats er niet tussen? Neem dan gerust contact op
          en dan zal ik kijken wat ik voor u kan betekenen.
        </p>
      </div>
    </section>

    <footer class="contact" id="contact">
      <div
        class="contact__background contact__background--email"
      ></div>
      <div class="inner">
        <h2 class="contact-title">Contact</h2>

        <form
          v-if="!formSubmitted"
          netlify
          id="contact-form"
          name="contact-form"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input
            type="hidden"
            name="form-name"
            value="contact-form"
          />
          <div class="name-email-wrapper">
            <div class="input-wrapper">
              <label for="name">Naam</label>
              <input
                type="text"
                id="name"
                name="name"
                class="form-input"
              />
            </div>
            <div class="input-wrapper">
              <label for="email">E-mailadres</label>
              <input
                type="text"
                id="email"
                name="email"
                class="form-input"
              />
            </div>
          </div>

          <div class="input-wrapper">
            <label for="message">Bericht</label>
            <textarea
              name="message"
              id="message"
              class="textarea"
            ></textarea>
          </div>

          <div class="button-wrapper">
            <button
              type="button"
              class="button"
              @click.prevent="submitContactForm"
            >
              Verstuur bericht
            </button>
          </div>
        </form>

        <div v-else>
          Bedankt voor je berichtje! Ik neem zo snel mogelijk contact
          met je op.
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',

  data() {
    return {
      formSubmitted: false,

      reviews: [
        {
          message: `"Fijne kraamweek gehad met Tamara.
          Goede begeleiding gehad en goede tips.
          Ook veel gezelligheid met haar als kraamverzorgster!"`,
          author: 'Kraamvrouw Marissa',
        },

        {
          message: `"Heb de kraamtijd met Tamara als zeer prettig ervaren!
            Tamara is erg toegankelijk in contact en kundig in wat ze doet.
            Zou haar iedereen aanraden!"`,
          author: 'Kraamvrouw Maaike',
        },

        {
          message: `"Heerlijke kraamweek gehad van de 3e, alles werd goed verzorgd!
            Tamara is een deskundige, lieve, behulpzame en kordate kraamverzorgster.
            Met als bonus heerlijke lunches en (voorbereiding van) avondeten."`,
          author: 'Kraamvrouw Marloes',
        },
      ],

      fbPosts: [
        {
          text: `Vandaag was het alweer de laatste dag van de
            kraamweek.<br />
            Wat vonden de broers het jammer dat ik weer moest gaan
            die vonden het wel erg gezellig en kreeg als cijfer
            wel een 690😬😀 nou daar doen we het voor🥰<br />
            <br />
            Ze waren erg tevreden over de goede zorgen de
            afgelopen week en konden erg genieten.<br />
            En om de week goed af te sluiten hebben we carpaccio
            gegeten en heb ik een super tof bedankje gehad🥰 een
            mooie tas met een leuke tekst.<br />
            <br />
            Dankjewel voor het schitterende kado.`,
          images: [
            'https://scontent-amt2-1.xx.fbcdn.net/v/t1.6435-0/p526x296/151275358_3612243885571854_4133339067809597223_n.jpg?_nc_cat=102&ccb=1-3&_nc_sid=110474&_nc_ohc=pVqodfSYlcYAX8N2pr_&_nc_ht=scontent-amt2-1.xx&tp=6&oh=49fd819e5f628bba8cd4d9031dc5b208&oe=6096FB1B',
            'https://scontent-amt2-1.xx.fbcdn.net/v/t1.6435-0/p526x296/151646947_3612243882238521_2202151599969999205_n.jpg?_nc_cat=101&ccb=1-3&_nc_sid=110474&_nc_ohc=qUziFqgSJzAAX898uBn&_nc_ht=scontent-amt2-1.xx&tp=6&oh=1993413b6812233c511c27b5918af017&oe=609741BD',
          ],
          link:
            'https://www.facebook.com/KraamzorgVanTamara/posts/3612244062238503',
        },
        {
          text: `💙💙 Hoera er zijn 2 prachtige jongens geboren een twin🥰💙💙<br>
Na maanden wachten zijn ze daar samen. <br>
Hun grote zus is nu al super trots op haar kleine broertjes🥰 <br>
Wat een geluk voor mij om zo’n goede hulp te mogen hebben deze week.<br>
We gaan er weer een fijne gezellige week van maken met de goede zorgen van Kraamzorg van Tamara`,
          images: [
            'https://scontent-amt2-1.xx.fbcdn.net/v/t1.6435-9/164923911_3709344749195100_6736400886780695504_n.jpg?_nc_cat=102&ccb=1-3&_nc_sid=8bfeb9&_nc_ohc=E3rIhdCeM0cAX8cJVFz&_nc_ht=scontent-amt2-1.xx&oh=65a6c887f5ec7ebd3ae95e5a357cd99b&oe=6096AF0F',
          ],
          link:
            'https://www.facebook.com/KraamzorgVanTamara/posts/3709344865861755',
        },
        {
          text: `Wow wat bijzonder is dat toch, bijzonder en leuk om bij een tweeling te mogen kramen.<br>
            <br>
            Het was alweer een aantal jaar geleden dat ik dat voor het laatst heb gedaan.<br>
            Maar zoiets verleer je niet het ging weer als van ouds,twee te gelijk dragen en boeren geen probleem dat kan ik wel.<br>
            <br>
            Is het geen plaatje🥰🥰 met de grote bos donkere haartjes.`,
          images: [
            'https://scontent-amt2-1.xx.fbcdn.net/v/t1.6435-9/168226896_3731422030320705_4848989973726974770_n.jpg?_nc_cat=109&ccb=1-3&_nc_sid=110474&_nc_ohc=xcprTpGSb-8AX-Y-ZR9&_nc_ht=scontent-amt2-1.xx&oh=8a5c852807825e5def733ba63ee81763&oe=60992E3C',
          ],
          link:
            'https://www.facebook.com/KraamzorgVanTamara/posts/3731422093654032',
        },
        {
          text: `Gisteren weer een zeer tevreden gezinnetje afgesloten.<br>
            Mevr vond het erg fijn dat er bij het starten van de zorg duidelijke afspraken zijn gemaakt zodat ze wisten wat ze van de kraamweek kon verwachten.<br>
            Ze heeft goed kunnen rusten en herstellen daar was ze erg blij mee.<br>
            Nu gaan ze als gezin verder.<br>
            <br>
            Als bedankje een mooie bos bloemen gehad en voor Vera ook een kleinigheidje`,
          images: [
            'https://scontent-ams4-1.xx.fbcdn.net/v/t1.6435-9/157939790_3659713427491566_5877197766377408676_n.jpg?_nc_cat=103&ccb=1-3&_nc_sid=8bfeb9&_nc_ohc=1-0JWXeyU_wAX8S3CbY&_nc_ht=scontent-ams4-1.xx&oh=215e0aeb9de58fe43cf75e4a6a3db0bd&oe=60968743',
          ],
          link:
            'https://www.facebook.com/KraamzorgVanTamara/posts/3659713564158219',
        },
      ],
    };
  },

  methods: {
    scrollToContactForm() {
      document
        .getElementById('contact')
        .scrollIntoView({ behavior: 'smooth' });
    },

    submitContactForm() {
      let myForm = document.getElementById('contact-form');
      let formData = new FormData(myForm);
      fetch('/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(formData).toString(),
      })
        .then(() => {
          this.formSubmitted = true;
        })
        .catch((error) => alert(error));
    },
  },
};
</script>

<style lang="scss">
$screen-sizes: (
  's': 320px,
  'm': 480px,
  'l': 768px,
  'xl': 1200px,
);

$max-width: 1192px;

$c-purple: #7c62a9;
$c-pink: #f68ed3;
$c-medium-pink: #fdebf7;
$c-light-pink: #fdf8fc;
$c-dark-pink: #b93b84;

* {
  box-sizing: border-box;
}

html,
body,
textarea,
input {
  font-family: 'Source Sans Pro', sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: $c-light-pink;
  color: $c-purple;

  font-size: 16px;
  line-height: 1.5;

  @media only screen and (min-width: map-get($screen-sizes, 'l')) {
    font-size: 18px;
  }
}

h1,
h2,
h3 {
  font-family: 'Fredoka One', sans-serif;
  font-weight: normal;
  color: $c-pink;
  margin: 0;
}

a {
  color: $c-purple;
}

p,
ul {
  max-width: 888px;
}

p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0 1em;
}

h1 {
  font-size: 28px;
  line-height: 1;
  margin-top: 56px;

  .highlight {
    color: $c-purple;
  }

  @media only screen and (min-width: map-get($screen-sizes, 'l')) {
    font-size: 56px;
    margin-top: 240px;
  }

  @media only screen and (min-width: map-get($screen-sizes, 'xl')) {
    font-size: 92px;
    margin-top: 324px;
  }
}

h2 {
  font-size: 30px;
  line-height: 1;
  margin-bottom: 8px;

  @media only screen and (min-width: map-get($screen-sizes, 'l')) {
    font-size: 42px;
    margin-bottom: 16px;
  }

  @media only screen and (min-width: map-get($screen-sizes, 'xl')) {
    margin-bottom: 24px;
  }
}

section,
footer {
  .inner {
    max-width: $max-width;
    margin: 0 auto;

    padding: 0 16px;

    @media only screen and (min-width: map-get($screen-sizes, 'l')) {
      padding: 0 40px;
    }

    @media only screen and (min-width: map-get($screen-sizes, 'xl')) {
      padding: 0 80px;
    }
  }

  & + & {
    margin-top: 24px;

    @media only screen and (min-width: map-get($screen-sizes, 'l')) {
      margin-top: 80px;
    }
  }
}

.align-center {
  text-align: center;
}

.inner {
  > *:not(h1, h2) + * {
    margin-top: 24px;
  }

  > li + li {
    margin-top: 8px;
  }
}

.hero {
  background-color: #ffffff;

  &__background {
    background-image: url('assets/hero.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right top;
    padding-bottom: 40px;
    // height: 100vh;

    // @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    //   (min-resolution: 144dpi) {
    //   background-image: url('assets/hero@2x.png');
    // }

    @media only screen and (min-width: map-get($screen-sizes, 'l')) {
      padding-bottom: 80px;
    }

    @media only screen and (min-width: map-get($screen-sizes, 'xl')) {
      padding-bottom: 124px;
    }
  }
}

.button-wrapper {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 16px;

  @media only screen and (min-width: map-get($screen-sizes, 'l')) {
    align-content: flex-start;
    justify-content: flex-start;
  }
}

.button {
  color: $c-dark-pink;
  font-weight: bold;
  font-size: 16px;
  background-color: $c-pink;
  padding: 16px 44px;
  border: none;
  border-radius: 999px;
}

.name-email-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  @media only screen and (min-width: map-get($screen-sizes, 'l')) {
    flex-direction: row;
  }

  @media only screen and (min-width: map-get($screen-sizes, 'l')) {
    .input-wrapper:last-child {
      margin-left: 16px;
    }
  }
}

.form-input,
.textarea {
  width: 100%;
  border: none;
  border-radius: 8px;
}

.input-wrapper {
  flex: 1;

  @media only screen and (max-width: map-get($screen-sizes, 'l')) {
    &:last-child {
      margin-top: 16px;
    }
  }
}

.form-input {
  height: 40px;
  padding: 0 8px;
}

.textarea {
  width: 100%;
  height: 130px;
  resize: none;
  padding: 8px;
}

.logo {
  width: 96px;
  margin-top: 40px;

  @media only screen and (min-width: map-get($screen-sizes, 'l')) {
    width: 200px;
  }
}

.contact-title {
  color: #ffffff;
  font-size: 24px;
  margin-bottom: 16px;

  @media only screen and (min-width: map-get($screen-sizes, 'l')) {
    margin-bottom: 24px;
    font-size: 42px;
  }
}

.contact {
  background-color: $c-purple;
  color: #ffffff;
  position: relative;
  overflow: hidden;

  @media only screen and (min-width: map-get($screen-sizes, 'xl')) {
    padding-left: 40%;
  }

  &__background {
    position: absolute;
    left: 50%;
    max-width: $max-width;
    top: 0;
    height: 110%;
    opacity: 0.4;
    background-repeat: no-repeat;

    background-size: contain;
    transform: translate(-50%, -5%);

    &--calendar {
      background-image: url('assets/calendar.png');
      @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
        (min-resolution: 144dpi) {
        background-image: url('assets/calendar.svg');
      }
    }

    &--email {
      background-image: url('assets/at-sign.png');
      @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
        (min-resolution: 144dpi) {
        background-image: url('assets/at-sign.svg');
      }
    }

    width: 170%;
    background-position: 50% 30%;

    @media only screen and (min-width: map-get($screen-sizes, 'm')) {
      width: 100%;
      background-position: 50% 110%;
    }

    @media only screen and (min-width: map-get($screen-sizes, 'xl')) {
      background-position: left center;
      opacity: 1;
      transform: translate(-58%, -5%);
    }
  }

  .inner {
    position: relative;
    padding: 24px 16px;

    @media only screen and (min-width: map-get($screen-sizes, 'l')) {
      padding: 56px 40px;
    }

    @media only screen and (min-width: map-get($screen-sizes, 'xl')) {
      padding: 56px 80px;
    }
  }

  .button {
    display: inline-block;
  }
}

.extra-info-wrapper {
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: map-get($screen-sizes, 'l')) {
    flex-direction: row;

    &.inner {
      max-width: $max-width + 80px;
    }
  }
}

.extra-info {
  background-color: $c-medium-pink;
  color: $c-purple;

  & + & {
    margin-top: 16px;
  }

  border-radius: 16px;
  padding: 20px 16px 16px 16px;

  @media only screen and (min-width: map-get($screen-sizes, 'l')) {
    border-radius: 40px;
    padding: 40px;
    flex: 1;

    & + & {
      margin-top: 0;
      margin-left: 40px;
    }
  }

  h2 {
    color: $c-purple;
    line-height: 1;
    margin-bottom: 16px;

    @media only screen and (min-width: map-get($screen-sizes, 'l')) {
      margin-bottom: 24px;
    }
  }
}

.map {
  max-width: 100%;
  border-radius: 24px;
  box-shadow: 0px 0px 36px 0px rgba(246, 142, 211, 0.4);
  margin: 24px 0;

  @media only screen and (max-width: map-get($screen-sizes, 'l')) {
    display: none;
  }
}

.photo-collage-desktop {
  display: none;
  float: right;

  @media only screen and (min-width: map-get($screen-sizes, 'l')) {
    display: inline;
    max-width: 400px;
  }
}

.photo-collage-mobile {
  display: block;
  margin: 0 auto;
  max-width: 100%;

  @media only screen and (min-width: map-get($screen-sizes, 'l')) {
    display: none;
  }
}

.reviews {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: map-get($screen-sizes, 'l')) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.review {
  max-width: 400px;

  @media only screen and (min-width: map-get($screen-sizes, 'l')) {
    max-width: 300px;
  }

  > p {
    max-width: 100%;
  }

  & + & {
    margin-top: 16px;
    @media only screen and (min-width: map-get($screen-sizes, 'l')) {
      margin-top: 0;
      margin-left: 32px;
    }
  }
}

.fb-posts {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  & + & {
    margin-top: 16px;
  }

  @media only screen and (min-width: map-get($screen-sizes, 'l')) {
    flex-direction: row;
    & + & {
      margin-top: 0;
    }

    &__col {
      max-width: calc(50% - 8px);

      &:last-child {
        margin-left: 16px;
      }
    }
  }

  @media only screen and (max-width: map-get($screen-sizes, 'l')) {
    &__col + &__col {
      margin-top: 16px;
    }
  }
}

.fb-p {
  background: white;
  border: 1px solid $c-medium-pink;
  box-shadow: 0px 0px 10px 0px rgba(246, 142, 211, 0.2);
  border-radius: 8px;
  margin: 0 auto;
  overflow: hidden;

  a {
    text-decoration: none;
  }

  & + & {
    margin-top: 16px;
  }

  &__text {
    padding: 16px;
    text-align: left;
  }

  &__photos {
    width: 100%;

    &__photo {
      height: 200px;
      background-size: cover;
      background-position: center;
    }

    > img {
      max-width: 100%;
      margin: 0;
      line-height: 0;
      display: block;
    }
  }
}

.social-link {
  white-space: nowrap;
}

.social-icon {
  transform: translateY(3px);
  margin-right: 4px;
}
</style>
